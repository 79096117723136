import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Grow from '@mui/material/Grow';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
// import FestivalTwoToneIcon from '@mui/icons-material/FestivalTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';

import paper from './paper.png';
import paperLowRes from './paper-low-res.png';
import tipis from './tipis4.png';
import church from './stwilfreds_outside.png';
// import envelope from './envelope.png'
// import flower from './flower.png';

import './App.css';


function App() {
  const [bgImgSrc, setBgImgSrc] = useState(paperLowRes || paper);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")

  useEffect(() => {
    const img = new Image();
    img.src = paper;
    img.onload = () => {
      setBgImgSrc(paper);
    };
  }, []);

  const [weddingDate, setDate] = useState('');

  useEffect(() => {
    (async function () {
      const { date } = await (await fetch(`/api/GetInvite?id=${id}`)).json();
      setDate(date);
    })();
  }, [id]);

  const theme = createTheme({
    typography: {
      h1: {
        fontFamily: [
          'Tangerine',
          'cursive',
        ].join(','),

      },
      h2: {
        fontFamily: [
          'Tangerine',
          'cursive',
        ].join(','),

      },
      h3: {
        fontFamily: [
          'Josefin Sans',
          'sans-serif',
        ].join(','),
      },
      h5: {
        fontFamily: [
          'Josefin Sans',
          'sans-serif',
        ].join(','),
      },
    },
  });

  theme.typography.h1 = {
    ...theme.typography.h1,
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    }
  };
  theme.typography.h3 = {
    ...theme.typography.h3,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    }
  };
  theme.typography.h2 = {
    ...theme.typography.h2,
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    }
  };
  theme.typography.h5 = {
    ...theme.typography.h5,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    }
  };

  const Card = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundImage: `url(${bgImgSrc})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      width: '50rem'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
    }

  }));

  const Footer = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${tipis})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      height: '4rem',
    },
    [theme.breakpoints.down('md')]: {
      height: '2rem',
    }
  }));

  const Text = styled(Typography)(({ theme }) => ({
    textShadow: '-1px -1px 1px rgba(30,30,30,.5), 1px 1px 1px rgba(255,255,255, 0.5)',
    color: '#3e3d3d',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  }));



  const Church = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${church})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      height: '1rem',
    }
  }));

  // const Flower = styled(Box)(({ theme }) => ({
  //   backgroundImage: `url(${flower})`,
  //   backgroundPosition: 'center',
  //   backgroundSize: '100% 100%',
  //   backgroundRepeat: 'no-repeat'
  // }))

  const Content = ({ weddingDate }) => (
    weddingDate ?
      <Grow in={true} timeout={2000}>
        <Card elevation={24} square>
          <Grid container direction='row'>
            {/* <Grid item xs={1}>
      <Flower height='100%'></Flower>
    </Grid> */}
            <Grid item xs={12}>
              <Grid container
                direction='column'
                // alignItems='center'
                // justifyContent='center'
                spacing={2}>
                <Grid item xs>
                  <Church height='2rem'></Church>
                </Grid>
                <Grid item xs="auto">
                  <Text variant='h1'>

                    Save the date
                  </Text>
                  <Text variant='h3' marginBottom='1rem' marginTop='1rem'>

                    {weddingDate}
                  </Text>
                  <Text variant='h2'>
                    Gemma Anne Colette Davis
                  </Text>
                  <Text variant='h5'>
                    &
                  </Text>
                  <Text variant='h2'>
                    Lewis Alexander Muirhead
                  </Text>

                </Grid>
                <Grid item xs={1}>
                  <Footer></Footer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grow> : <div style={{ position: 'absolute' }}>
        <Fab
          aria-label="save"
          color="primary"
          sx={{ cursor: 'pointer' }}
        >

          <FavoriteIcon></FavoriteIcon>
        </Fab>
        <CircularProgress
          size={68}
          sx={{
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }} />
      </div>);

  return (
    <ThemeProvider theme={theme}>
      <Grid container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Content weddingDate={weddingDate}></Content>
        </Grid>
      </Grid>
    </ThemeProvider>);
}

export default App;
